/* cyrillic-ext */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Roboto"), local("Roboto-Regular"),
    url(https://fonts.gstatic.com/s/roboto/v20/KFOmCnqEu92Fr1Mu72xKOzY.woff2)
      format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Roboto"), local("Roboto-Regular"),
    url(https://fonts.gstatic.com/s/roboto/v20/KFOmCnqEu92Fr1Mu5mxKOzY.woff2)
      format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Roboto"), local("Roboto-Regular"),
    url(https://fonts.gstatic.com/s/roboto/v20/KFOmCnqEu92Fr1Mu7mxKOzY.woff2)
      format("woff2");
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Roboto"), local("Roboto-Regular"),
    url(https://fonts.gstatic.com/s/roboto/v20/KFOmCnqEu92Fr1Mu4WxKOzY.woff2)
      format("woff2");
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Roboto"), local("Roboto-Regular"),
    url(https://fonts.gstatic.com/s/roboto/v20/KFOmCnqEu92Fr1Mu7WxKOzY.woff2)
      format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Roboto"), local("Roboto-Regular"),
    url(https://fonts.gstatic.com/s/roboto/v20/KFOmCnqEu92Fr1Mu7GxKOzY.woff2)
      format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Roboto"), local("Roboto-Regular"),
    url(https://fonts.gstatic.com/s/roboto/v20/KFOmCnqEu92Fr1Mu4mxK.woff2)
      format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local("Roboto Medium"), local("Roboto-Medium"),
    url(https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmEU9fCRc4EsA.woff2)
      format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local("Roboto Medium"), local("Roboto-Medium"),
    url(https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmEU9fABc4EsA.woff2)
      format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local("Roboto Medium"), local("Roboto-Medium"),
    url(https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmEU9fCBc4EsA.woff2)
      format("woff2");
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local("Roboto Medium"), local("Roboto-Medium"),
    url(https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmEU9fBxc4EsA.woff2)
      format("woff2");
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local("Roboto Medium"), local("Roboto-Medium"),
    url(https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmEU9fCxc4EsA.woff2)
      format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local("Roboto Medium"), local("Roboto-Medium"),
    url(https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmEU9fChc4EsA.woff2)
      format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local("Roboto Medium"), local("Roboto-Medium"),
    url(https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmEU9fBBc4.woff2)
      format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: local("Roboto Black"), local("Roboto-Black"),
    url(https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmYUtfCRc4EsA.woff2)
      format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: local("Roboto Black"), local("Roboto-Black"),
    url(https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmYUtfABc4EsA.woff2)
      format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: local("Roboto Black"), local("Roboto-Black"),
    url(https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmYUtfCBc4EsA.woff2)
      format("woff2");
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: local("Roboto Black"), local("Roboto-Black"),
    url(https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmYUtfBxc4EsA.woff2)
      format("woff2");
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: local("Roboto Black"), local("Roboto-Black"),
    url(https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmYUtfCxc4EsA.woff2)
      format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: local("Roboto Black"), local("Roboto-Black"),
    url(https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmYUtfChc4EsA.woff2)
      format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: local("Roboto Black"), local("Roboto-Black"),
    url(https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmYUtfBBc4.woff2)
      format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

.addmargin {
  margin-top: 10px;
  margin-bottom: 10px;
}

.centeralign {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

#root {
  background-image: url("./assets/background.png") ;
  background-size:     cover;                    
    background-repeat:   round;
    background-position: center center;
   height: 100%;
}

.App {
  font-family: "Roboto";
  text-align: center;
  text-align: center;
  width: 100%;
  margin: 0 auto;
  color: #154c79;
  
  /* background: #f7f7f7; */
}

.App-logo {
  background-image: url("./assets/TTG_Cfactor_LOGO_2-03.0b8174e6.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 400px;
  height: 106px;
  margin-top: 30px;
  float: left;
  position: absolute;
  top: 0;
  left: 0;
}

.App-header {
  /* background-color: #222; */
  height: 150px;
  padding: 20px;
  color: white;
  position: relative;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.form_div {
  margin-top: 50px;
}
.heading {
  font-size: 20px;
  font-weight: 500;
}
.questions {
  /* background: white; */
  min-height: 400px;
  padding: 50px 70px;
  text-align: left;
  margin-bottom: 50px;
}
.ques {
  font-size: 18px;
  font-weight: 500;
  margin: 50px 0px;
}
.options {
  margin-top: 10px;
  /* margin-left: 50px; */
}

.semail {
  border-radius: 10px;
  border: 1px solid #1b4a64;
  padding: 5px 10px;
  width: 280px;
}
.semail::-webkit-input-placeholder {
  color: gray;
  font-size: 16px;
}
.semail:-ms-input-placeholder {
  color: gray;
  font-size: 16px;
}
.semail::-ms-input-placeholder {
  color: gray;
  font-size: 16px;
}
.semail::placeholder {
  color: gray;
  font-size: 16px;
}

.scale {
  margin: 0.5rem;
  /* font-size: 21px; */
  font-weight: 500;
  display: inline-block !important;
}

/* for radio starts */
.radio {
  margin: 0.5rem;
  /* font-size: 21px; */
  font-weight: 500;
}
.radio input[type="radio"] {
  position: absolute;
  border: 2px solid #F26D2B;
  opacity: 1;
}
.radio-label {
  /* font-weight: 500; */
  /* color: #525252; */
  color:black
  
}
.radio input[type="radio"] + .radio-label:before {
  content: "";
  background: #f4f4f4;
  border-radius: 100%;
  /* border: 1px solid #b4b4b4; */
  display: inline-block;
  width: 0.8em;
  height: 0.8em;
  position: relative;
  top: 0.25em;
  margin-right: 1em;
  vertical-align: top;
  cursor: pointer;
  text-align: center;
  -webkit-transition: all 250ms ease;
  -o-transition: all 250ms ease;
  transition: all 250ms ease;
}
.radio input[type="radio"]:checked + .radio-label:before {
  border: 1px solid #0DFF92;
  background: #0DFF92;

  -webkit-box-shadow: inset 0 0 0 3px #f4f4f4;
  box-shadow: inset 0 0 0 3px #f4f4f4;
}
.radio input[type="radio"]:focus + .radio-label:before {
  outline: none;
  border-color: #fcd905;
}
.radio input[type="radio"]:disabled + .radio-label:before {
  -webkit-box-shadow: inset 0 0 0 3px #f4f4f4;
  box-shadow: inset 0 0 0 3px #f4f4f4;
  border-color: #b4b4b4;
  background: #b4b4b4;
}
.radio input[type="radio"] + .radio-label:empty:before {
  margin-right: 0;
}
.inputt {
  margin: 20px 0px;
}
.inputt label {
  margin-right: 10px;
}
.input-submit {
  text-align: center;
}
.input-submit span {
  font-size: 20px;
  background: #1b4a64;
  padding: 8px 20px;
  border-radius: 10px;
  color: white;
  cursor: pointer;
}
#sucesspop {
  border-radius: 10px;
  position: fixed;
  top: 43%;
  background: white;
  width: 400px;
  height: 200px;
  margin-right: -200px;
  right: 50%;
  z-index: 999;
  /* display:none; */
}
.success-inner {
  display: table;
  height: 100%;
}
#sucesspop .imesg {
  display: table-cell;
  vertical-align: middle;
  padding: 10px;
  font-size: 16px;
}
.closediv {
  /* position: absolute;
  right: 15px;
  width: 30px;
  top: 10px; */
  cursor: pointer;
}
.closediv img {
  width: 100%;
  height: 100%;
}
#background-layer {
  display: none;
  width: 100%;
  position: fixed;
  height: 100vh;
  background: white;
  z-index: 99;
  left: 0;
  top: 0;
}
#okb {
  display: table-row !important;
  vertical-align: middle;
}
#okb span {
  background: #f26b28;
  padding: 10px 50px;
  border-radius: 10px;
  color: white;
}
.checkbox label,
.radio label {
  padding-left: 0px !important;
}



@media only screen and (max-width:500px){
 .mainRow{
   margin-top: -30px !important;
 }
  #root {
    min-width: 400px;
    background-image: url("./assets/background.png") ;
    background-size: auto auto;
    background-size: 100% 100%;                 
      background-repeat:   no-repeat;
      background-position:top;
 
      height: 100rem !important;
 
      margin: 0 auto !important;
  
     
  }
   .App {
       width: 80% ;
      margin: 0 auto !important;
     }
     .wrapApp{
       height: 100;
     }


      .App-header {
        height: 0px;
       }

      .h1,h1{
        font-size: 1.5rem !important;
      }
        .heading {
           font-size: 12px;
         }

        .App-logo {
          margin-top: 50px;
           background-size: 150px 50px;
           width: 200px;
         }

         .htmlForm_div{
           margin-top:3rem !important;
         }
         .submit{
           text-align: center !important;
           vertical-align: middle !important;
           height: 2rem;
           width: 6rem;
           font-size: 10px !important;
           line-height: 10px !important;
         }
}

@media screen and (max-width: 992px) {
  
  #root {
    min-width: 400px;
    background-image: url("./assets/background.png") ;
    background-size: auto auto;
    background-size: 100% 100%;                 
      background-repeat:   no-repeat;
      background-position:top;
      height: 140rem;
      margin: 0 auto !important;
  
     
  }
  
}

/* form style */

/* form style end */